const initAnisMap = (element) => {
  const anisMapInstance = Object.assign({}, AnisMap);
  anisMapInstance.config = {
    useInstances: true,
    scriptid: element.id,
    iconurl: element.dataset.iconurl,
    mapparamsurl: element.dataset.mapparamsurl,
    geojsonurl: element.dataset.geojsonurl,
    wosearchurl: element.dataset.wosearchurl,
    mapgeojsonurl: element.dataset.mapgeojsonurl,
    ignoreTitle: true,
    mapid: element.id,
    searchids: element.dataset.searchids,
  };
  AnisMapInstances[anisMapInstance.config.scriptid] = anisMapInstance;
  anisMapInstance.initById(element.dataset.mapid);

  initLegend(element, anisMapInstance);
  initClearSearch(element.parentElement, anisMapInstance);
}

const initLegend = (element, instance) => {
  const legend = element.parentElement.querySelector('.map_legend');

  if (!legend) {
    return;
  }

  legend.innerHTML = '';

  const fetchUrl = element.dataset.mapparamsurl + element.dataset.mapid;

  fetch(fetchUrl).then(response => response.json()).then((response) => {

    response.layers.forEach((layer) => {
      let legenddiv = document.createElement('div');
      let svg = document.createElement('img');
      let titlediv = document.createElement('div');
      const pinId = 'pin_' + element.id;

      svg.setAttribute('id', pinId);
      svg.setAttribute('src', element.dataset.svgurl + '?id=' + layer.id + '&mapid=' + element.dataset.mapid);
      svg.style.scale = '0.7';

      titlediv.append(layer.title);

      legenddiv.style.display = 'flex';
      legenddiv.style.alignItems = "center";

      legenddiv.appendChild(svg);
      legenddiv.append(titlediv);

      legend.appendChild(legenddiv);
    });

  });
}

const initClearSearch = (element, instance) => {
  document.addEventListener("DOMContentLoaded", function() {
    const clearButton = element.querySelector("[data-part='clear-search']");
    const searchInput = element.querySelector("input[name^='cps_search_']");
    const mapContainer = element.querySelector("[id^='map_']");

    if (clearButton && searchInput && mapContainer) {
      clearButton.addEventListener("click", function(event) {
        event.preventDefault();
        searchInput.value = "";
        window.history.pushState({}, document.title, window.location.pathname);
        mapContainer.innerHTML = "";
        location.reload();
      });
    }
  });
}

export {
  initAnisMap,
}
